import styled from 'styled-components'
import Link from 'next/link'

import { documentTypeExtension } from '../../utils/general'
import Icons from '../icons'

interface LinkProps {
	title: string | undefined
	href: string | undefined
	enableFileIcon?: boolean
}

const LinkItem: React.FC<LinkProps> = ({
	title,
	href,
	enableFileIcon = false,
}) => {
	const fileExtension: string = (href?.split('.')?.pop() || '').toUpperCase()

	const Icon =
		Icons[documentTypeExtension(fileExtension) ? 'GenericFile' : 'Union']

	return (
		<Wrapper>
			<StyledLink href={href ?? '#'}>
				{enableFileIcon && <Icon />}
				{title}
			</StyledLink>
		</Wrapper>
	)
}

const StyledLink = styled(Link)`
	display: flex;
	gap: 10px;
	align-items: center;
	font-weight: 500;
	width: 100%;
	padding-block: 16px;
	color: ${(p) => p.theme.colors.gold550};
`

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	color: ${(p) => p.theme.colors.gold550};

	${StyledLink} {
		border-top: 1px solid ${(p) => p.theme.colors.grey100};
	}

	&:last-child {
		border-bottom: 1px solid ${(p) => p.theme.colors.grey100};
	}
`

export default LinkItem
