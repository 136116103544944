import Image from 'next/image'
import React from 'react'

import { IPrismicImage } from '../../types/prismic/baseTypes'
import { $ElementProps } from '../../utils/tsHelpers'

export interface IPrismicImageProps
	extends Omit<
		$ElementProps<typeof Image>,
		'src' | 'width' | 'height' | 'alt'
	> {
	image?: IPrismicImage
	className?: string
}

export default function PrismicImage({
	image,
	className,
	layout = 'responsive',
	...otherProps
}: IPrismicImageProps) {
	return (
		<Image
			className={className}
			src={image?.url ?? ''}
			layout={layout}
			width={image?.dimensions?.width}
			height={image?.dimensions?.height}
			alt={image?.alt ?? ''}
			{...otherProps}
		/>
	)
}
