import styled from 'styled-components'

import {
	IBaseSlice,
	IPrismicDocumentLink,
} from '../../../types/prismic/baseTypes'
import { FullBleedGrid } from '../../Layout/Grid'
import LinkList from '../../LinkList/LinkList'

export interface ILinkListSlice extends IBaseSlice {
	primary: {
		link_list_title: string
	}
	items: {
		link_title: string
		link: IPrismicDocumentLink
	}[]
	slice_type: 'link_list'
}

interface LinkListSliceProps {
	sliceData: ILinkListSlice
	className? :string
}

const LinkListSlice: React.FC<LinkListSliceProps> = ({ sliceData, className }) => {
	const hasTitle = !!sliceData.primary.link_list_title
	return (
		<FullBleedGrid className={className}>
			<LinkListWrapper>
				{hasTitle && (
					<LinkListTitle>
						{sliceData.primary.link_list_title.toUpperCase()}
					</LinkListTitle>
				)}
				<LinkList items={sliceData.items} displayDropdown />
			</LinkListWrapper>
		</FullBleedGrid>
	)
}

export default LinkListSlice

const LinkListTitle = styled.h3`
	color: ${(p) => p.theme.colors.gold550};
	margin-block: 1rem;
`

export const LinkListWrapper = styled.div`
	margin-top: var(--spacer-lg);
	grid-column: 2 / -2;
`
