import { useEffect, useMemo, useState } from 'react'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import styled from 'styled-components'

import { IPrismicLink } from '../../types/prismic/baseTypes'
import { usePrismicRoutingContext } from '../../utils/prismic/Context'
import { resolvePrismicLink } from '../../utils/prismic/RouteHelpers'

import LinkItem from './LinkItem'
import LinkListArrow from './LinkListArrow'

export interface ILinkListItem {
	link_title: string
	link: IPrismicLink
}

interface LinkListProps {
	items: ILinkListItem[]
	displayDropdown?: boolean
}

const MAX_LINK_COUNT = 3

const LinkList: React.FC<LinkListProps> = ({
	items,
	displayDropdown = false,
}) => {
	const { pages } = usePrismicRoutingContext()
	const [showAllLinks, setShowAllLinks] = useState<boolean>(false)
	const [parent] = useAutoAnimate({ duration: 500 })
	const initialItems = useMemo(() => items.slice(0, MAX_LINK_COUNT), [items])
	const [links, setLinks] = useState<ILinkListItem[]>(
		items.length > MAX_LINK_COUNT ? initialItems : items
	)

	useEffect(() => {
		if (showAllLinks) {
			setLinks(items)
		} else {
			setLinks(initialItems)
		}
	}, [showAllLinks, setLinks, items, initialItems])

	const contentLength = items.length

	return (
		<>
			{/*@ts-ignore*/}
			<LinkListContainer ref={parent}>
				{links.map((item, index) => {
					return (
						<StyledListItem
							index={index + 1}
							key={index}
							showAllLinks={showAllLinks}
						>
							<LinkItem
								key={`${item.link.id}-${index}`}
								href={resolvePrismicLink(item.link, pages)}
								title={item.link_title}
								enableFileIcon
							/>
						</StyledListItem>
					)
				})}
			</LinkListContainer>
			{displayDropdown && contentLength > 3 && (
				<LinkListArrow
					showAllLinks={showAllLinks}
					toggleDocuments={() => setShowAllLinks((prev) => !prev)}
				/>
			)}
		</>
	)
}

export default LinkList

const LinkListContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const StyledListItem = styled.div<{
	index: number
	showAllLinks: boolean
}>`
	${(props) =>
		props.index > MAX_LINK_COUNT &&
		!props.showAllLinks && {
			display: 'none',
		}}
`
