import React from 'react'
import styled from 'styled-components'
import Button, { ButtonBackgroundType, IconPlacement } from '@kvika/button'

import useLocalization from '../../hooks/utils/use-localization'
import { CollapsableArrow, ExtendArrow } from '../icons'

interface IProps {
	showAllLinks: boolean
	toggleDocuments: () => void
}

const LinkListArrow: React.FC<IProps> = ({ showAllLinks, toggleDocuments }) => {
	const { translate } = useLocalization()
	return (
		<ArrowButton
			backgroundType={ButtonBackgroundType.Text}
			icon={showAllLinks ? <CollapsableArrow /> : <ExtendArrow />}
			iconPlacement={IconPlacement.RIGHT}
			onClick={toggleDocuments}
		>
			{showAllLinks
				? translate('slices.linkList.collapseDocs')
				: translate('slices.linkList.viewAllDocs')}
		</ArrowButton>
	)
}

export default LinkListArrow

const ArrowButton = styled(Button)`
	font-weight: 500;
	font-family: AkzidenzGroteskPro;
	margin-left: var(--spacer-sm);
	color: ${(p) => p.theme.colors.gold550};
`
