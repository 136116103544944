import { reduce, set } from 'lodash'
import { RichTextBlock } from 'prismic-reactjs'
import * as React from 'react'
import styled from 'styled-components'

import { IBaseSlice } from '../../types/prismic/baseTypes'
import Accordion from '../Accordion/Accordion'
import { Grid } from '../Layout/Grid'
import RichtextTable from '../Table/RichTextTable'




export interface IProps {
	sliceData: IFourColTable | ISevenColTable | IEightColTable
	className?: string
}

export function RichTextTableSliceWrapper({ sliceData, className }: IProps) {

	const columns = React.useMemo(
		() => extractColumnsFromPrismicTable(sliceData.primary),
		[sliceData.primary]
	) as {
		[key: string]: { heading?: string; width?: string }
	}



	const hasAccordion = sliceData.primary.has_accordion

	return (
		<Grid className={className}>
			{hasAccordion ? (
				<StyledAccordion heading={sliceData.primary.table_title}>
					<RichtextTable columns={columns} items={sliceData.items} />
				</StyledAccordion>
			) : (
				<ContentWrapper>
					<RichtextTable columns={columns} items={sliceData.items} />
				</ContentWrapper>
			)}
		</Grid>
	)
}

const ContentWrapper = styled.div`
	grid-column: 1 / -1;
`

const StyledAccordion = styled(Accordion)`
	grid-column: 1 / -1;
`

export const extractColumnsFromPrismicTable = (primary: any) => {
	return reduceColumns(primary)
}

const reduceColumns = (
	primary: IProps['sliceData']['primary']
): {
	[key: string]: { heading?: string | undefined; width?: string | undefined }
} => {
	return reduce(
		primary,
		(result, value, key) => {
			if (!key.includes('column') || !value) {
				return result
			}
			const split = key.split('_')
			const columnName = `${split[0]}_${split[1]}`
			const isHeading = key.includes('heading')
			const returnValue = set(
				result,
				`${columnName}.${isHeading ? 'heading' : 'width'}`,
				value
			)
			return returnValue
		},
		{}
	)
}


export interface IFourColTable extends IBaseSlice {
	primary: {
		table_title: string
		show_title: boolean
		has_accordion: boolean
		column_1_heading: string
		column_1_width: string
		column_2_heading: string
		column_2_width: string
		column_3_heading: string
		column_3_width: string
		column_4_heading: string
		column_4_width: string
	}
	items: {
		col1?: RichTextBlock[]
		col2?: RichTextBlock[]
		col3?: RichTextBlock[]
		col4?: RichTextBlock[]
	}[]

	slice_type: 'four_col_table_test'
}

export interface ISevenColTable extends IBaseSlice {
	primary: {
		table_title: string
		show_title: boolean
		has_accordion: boolean
		column_1_heading: string
		column_2_heading: string
		column_3_heading: string
		column_4_heading: string
		column_5_heading: string
		column_6_heading: string
		column_7_heading: string
	}
	items: {
		col1?: RichTextBlock[]
		col2?: RichTextBlock[]
		col3?: RichTextBlock[]
		col4?: RichTextBlock[]
		col5?: RichTextBlock[]
		col6?: RichTextBlock[]
		col7?: RichTextBlock[]
	}[]

	slice_type: 'table__up_to_7_col_'
}
export interface IEightColTable extends IBaseSlice {
	primary: {
		table_title: string
		show_title: boolean
		has_accordion: boolean
		column_1_heading: string
		column_2_heading: string
		column_3_heading: string
		column_4_heading: string
		column_5_heading: string
		column_6_heading: string
		column_7_heading: string
		column_8_heading: string
		column_1_width: string
		column_2_width: string
		column_3_width: string
		column_4_width: string
		column_5_width: string
		column_6_width: string
		column_7_width: string
		column_8_width: string
	}
	items: {
		col1?: RichTextBlock[]
		col2?: RichTextBlock[]
		col3?: RichTextBlock[]
		col4?: RichTextBlock[]
		col5?: RichTextBlock[]
		col6?: RichTextBlock[]
		col7?: RichTextBlock[]
		col8?: RichTextBlock[]
	}[]

	slice_type: 'eight_col_tablet'
}