import React from 'react'
import { map, reduce, set, some } from 'lodash'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import styled from 'styled-components'

import Accordion from '../Accordion/Accordion'
import HtmlSerializer from '../HtmlSerializer/htmlSerializer'

import {
	StyledTable,
	StyledTableData,
	StyledTableHeader,
	StyledTableRow,
} from './styles'

interface IProps {
	columns: {
		[key: string]: { heading?: string; width?: string }
	}
	items: {
		[key: string]: RichTextBlock[] | undefined
	}[]
}

const RichtextTable: React.FC<IProps> = ({ columns, items }) => {
	// * We want to conditionally impact the padding of the StyledTableHeader, as rendering it without content will mess up margins
	// * We still want to render the header in that case, as it sets the table column width
	const hasHeader = some(columns, 'heading')

	return (
		<StyledTable>
			<thead style={{ height: 0, padding: 0 }}>
				<tr>
					{map(columns, (column, key) => {
						return (
							<StyledTableHeader
								hasHeader={hasHeader}
								key={`table-head-${key}`}
								width={column.width ?? ''}
							>
								{column.heading}
							</StyledTableHeader>
						)
					})}
				</tr>
			</thead>
			<tbody>
				{map(items, (rows, rowKey) => {
					return (
						<StyledTableRow key={`table-row-${rowKey}`}>
							{map(rows, (cell, cellKey) => {
								return cell && cell.length ? (
									<StyledTableData key={`table-row-${rowKey}-cell-${cellKey}`}>
										<RichText
											render={cell}
											htmlSerializer={(...args) =>
												HtmlSerializer(...args, {
													enableMediaLinks: false,
													enableFileIconAnchors: true,
												})
											}
										/>
									</StyledTableData>
								) : null
							})}
						</StyledTableRow>
					)
				})}
			</tbody>
		</StyledTable>
	)
}

RichtextTable.propTypes = {}

const ContentWrapper = styled.div`
	grid-column: 1 / -1;
`

const StyledAccordion = styled(Accordion)`
	grid-column: 1 / -1;
`

export default RichtextTable
