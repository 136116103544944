import React, { ReactNode, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'

import CollapsableArrow from '../icons/CollapsableArrow'
import { media } from '../../utils/style-utils'


interface IProps {
	heading: string
	children: ReactNode
	className?: string
}

const ANIMATION_DURATION = {
	transition: {
		duration: 0.33,
		type: 'tween',
	},
}

const INITIAL_ANIMATION_STATE = {
	...ANIMATION_DURATION,
	opacity: 0,
	height: 0,
	y: -5,
}

const ACTIVE_ANIMATION_STATE = {
	...ANIMATION_DURATION,
	opacity: 1,
	y: 0,
	height: 'auto',
}

const Accordion: React.FC<IProps> = ({ heading, children, className }) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<Container className={className}>
			<ToggleContainer>
				<Toggle key='toggler' onClick={() => setIsOpen((prev) => !prev)}>
					<Heading>{heading}</Heading>
					<ArrowWrapper
						key='arrow'
						animate={{ rotateZ: isOpen ? 0 : -180, ...ANIMATION_DURATION }}
					>
						<CollapsableArrow />
					</ArrowWrapper>
				</Toggle>
			</ToggleContainer>
			<Content>
				<AnimatePresence>
					{isOpen ? (
						<StyledAccordionContent
							key='content'
							initial={INITIAL_ANIMATION_STATE}
							animate={ACTIVE_ANIMATION_STATE}
							exit={INITIAL_ANIMATION_STATE}
						>
							<StyledAccordionInnerContent>
								{children}
							</StyledAccordionInnerContent>
						</StyledAccordionContent>
					) : (
						''
					)}
				</AnimatePresence>
			</Content>
		</Container>
	)
}

export default Accordion

const Container = styled.div`
	border-top: 1px solid ${({ theme }) => theme.colors.grey100};

	&:last-of-type {
		border-bottom: 1px solid ${(p) => p.theme.colors.grey100};
	}
	font-size: 1.125rem;
`
const Content = styled.div`
	font-size: 1rem;
`

const Toggle = styled(motion.div)`
	font-weight: 500;
	cursor: pointer;
	padding: 16px 8px;
	color: #333;
	border-radius: 4px;
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;
`

const ToggleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	transition: background-color 300ms ease;
	&:hover {
		background-color: #eee;
	}
`

const StyledAccordionContent = styled(motion.div)`
	/* background-color: #fff; */
`

export const StyledAccordionInnerContent = styled.div`
	// ! This is a hack to bypass a framer-motion measuring bug
	// ! Removing this will cause the accordion to jump slightly when exiting

	padding: 8px 32px;

	${media.desktopSm`
		padding: 0 16px;
	`}
`

const ArrowWrapper = styled(motion.span)`
	display: flex;
`
const Heading = styled.h2`
	margin: 0;
`
