export function interleave<Type>(arr: Type[], thing: Type): Type[] {
	return ([] as Type[]).concat(...arr.map((n) => [n, thing])).slice(0, -1)

}

export const documentTypeExtension = (fileExtension: string): boolean =>
	fileExtension === 'PDF' ||
		fileExtension === 'DOC' ||
		fileExtension === 'DOCX' ||
		fileExtension === 'ODT' ||
		fileExtension === 'XLS' ||
		fileExtension === 'XLSX' ||
		fileExtension === 'ODS' ||
		fileExtension === 'PPT' ||
		fileExtension === 'PPTX' ||
		fileExtension === 'TXT'
		? true
		: false

export const replaceDoubleHyphen = (text: string, replaceWith = String.fromCharCode(173)): string | null => {
	if (!text || text === '') {
		return null
	}
	return text.replace(/--/g, replaceWith);
}