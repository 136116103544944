import React, { HTMLProps } from 'react'

import { IPrismicImage } from '../../types/prismic/baseTypes'
import { $ElementProps } from '../../utils/tsHelpers'

interface IPrismicImageProps
	extends Omit<
		HTMLProps<HTMLImageElement>,
		'src' | 'width' | 'height' | 'alt'
	> {
	image: IPrismicImage
}

export default function PrismicImg({ image, className }: IPrismicImageProps) {
	return (
		<img
			className={className}
			src={image.url}
			width={image.dimensions?.width}
			height={image.dimensions?.height}
			alt={image.alt ?? ""}
		/>
	)
}
