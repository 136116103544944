import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import { IPrismicLink } from '../../types/prismic/baseTypes'
import { usePrismicRoutingContext } from '../../utils/prismic/Context'
import { resolvePrismicLink } from '../../utils/prismic/RouteHelpers'

export interface IProps {
	link?: IPrismicLink
	ariaLabel?: string
	className?: string
	target?: string
	children?: React.ReactNode
}

const PrismicLink: FunctionComponent<IProps> = ({
	link,
	children,
	ariaLabel,
	className,
	target,
}) => {
	const pages = usePrismicRoutingContext().pages

	const modifiedLink: string =
		link && link.link_type !== 'Any'
			? resolvePrismicLink(link, pages) ?? '/'
			: ''

	return (
		<StyledAnchor
			target={target}
			href={modifiedLink}
			className={className}
			aria-label={ariaLabel}
		>
			{children}
		</StyledAnchor>
	)
}

export default PrismicLink
const StyledAnchor = styled(Link)`
	display: flex;
	gap: 8px;
`
