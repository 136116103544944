import React from 'react'
import { RichText as PrismicRichText, RichTextBlock } from 'prismic-reactjs'

import HtmlSerializer, {
	SerializerOptions,
} from '../HtmlSerializer/htmlSerializer'

interface Props {
	content?: RichTextBlock[]
	className?: string
	serializerOptions?: SerializerOptions
}

const RichText: React.FC<Props> = ({
	content,
	className,
	serializerOptions,
}) => {
	return (
		<div className={className}>
			<PrismicRichText
				render={content}
				htmlSerializer={(...args) => HtmlSerializer(...args, serializerOptions)}
			/>
		</div>
	)
}

export default RichText
