import Image from 'next/image'
import React from 'react'
import styled, { css } from 'styled-components'

import { IBaseSlice, IPrismicImage } from '../../types/prismic/baseTypes'
import { FullBleedGrid } from '../Layout/Grid'

type LayoutType = 'fixed size' | 'fill content area' | 'full width'

export interface IImageSlice extends IBaseSlice {
	primary: {
		image: IPrismicImage
		layout: LayoutType
	}
	slice_type: 'image'
}

interface IProps {
	sliceData: IImageSlice
	className?: string
}

const ImageSlice: React.FC<IProps> = ({ sliceData, className }) => {
	return (
		<GridContainer className={className} $layout={sliceData.primary.layout}>
			<ContentContainer $layout={sliceData.primary.layout}>
				<StyledImage
					$layout={sliceData.primary.layout}
					src={sliceData.primary.image.url}
					alt={sliceData.primary.image.alt || ''}
					width={sliceData.primary.image.dimensions?.width}
					height={sliceData.primary.image.dimensions?.height}
				/>
			</ContentContainer>
		</GridContainer>
	)
}

export const GridContainer = styled(FullBleedGrid)<{ $layout: LayoutType }>`
	${(props) => props.$layout !== 'full width' && 'padding: 40px 0;'}
`

export const ContentContainer = styled.div<{ $layout: LayoutType }>`
	justify-content: center;
	${(props) =>
		props.$layout === 'full width'
			? 'grid-column: 1 / -1;'
			: 'grid-column: 2 / -2;'}
`

const StyledImage = styled(Image)<{ $layout: LayoutType }>`
	height: auto !important;
	${(props) =>
		props.$layout === 'fixed size'
			? 'max-width: 100%;'
			: 'width: 100% !important;'}
`

export default ImageSlice
